/* You can add global styles to this file, and also import other style files */
@import 'node_modules/material-design-icons/iconfont/material-icons.css';

body {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;
  font-family: "OpenSans", Arial, sans-serif;
}

.btn:not(.btn-link) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  border-radius: 0;
  outline: none;
  height: 2.1rem;
  width: 100%;
  font-size: 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

textarea {
  height: 45px;
  width: 100%;
  background-color: transparent;
}

a {
  text-decoration: none;
}

.btn {
  font-size: 0.8rem;
  padding: 0.85rem 2.13rem;
  margin: 6px;
  border-radius: 2px;
  border: 0;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  text-transform: uppercase;
  white-space: normal !important;
  word-wrap: break-word;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden !important;
}

.dragula {
  border-right: solid 1px #e5e5e5;
  height: 100%;
}

.timepicker {
  .bs-chevron {
    color: #000;
  }

  .btn {
    margin: 0 !important;
    padding: 5px 10px;
  }

  .form-group {
    margin-bottom: 5px;

    input {
      width: 80px;
      padding: 1px 0 2px;
      margin: 0;
      font-size: 22px;
      color: #000;
      border-color: rgba(0, 0, 0, 0.42);
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.iti--container{
  z-index: 1200;
}

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "node_modules/dragula/dist/dragula.css";
@import "node_modules/quill/dist/quill.snow.css";